/* styles.css */
.main-cuadro {
    background-color: var(--secundario);
    margin: 0rem;
    width: 100%;
    padding-bottom: 1rem;
}

.cuadros {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0rem 3rem;
}

.main-cuadro-title {
    color: var(--blanco);
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.cuadro {
    /*flex: 1 0 24rem;*/
    width: 15rem;
    height: 18rem;
    margin: .8rem;
    background-color: var(--blanco);
    border-radius: .5rem;
    padding: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: .1rem solid var(--blanco);
    transition: 0.3s;
}

.cuadro:hover {
    box-shadow: 2px 2px 8px var(--negro);
    transform: scale(1.03);
}

.cuadro-cover:hover .cuadro-img {
    filter: blur(1px);
}

.cuadro .cuadro-cover {
    position: relative;
    height: auto;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s;
    overflow: hidden;
    width: 100%;
}

.cuadro-cover {
    border-radius: .5rem;
}

.cuadro .cuadro-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5rem;
}

.cuadro-cover img:hover {
    transform: scale(1.01);
}

.cuadro .cuadro-cover-message {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    color: var(--blanco);
    padding: 10px;
    font-size: 1rem;
    opacity: 0;
    transition: 0.3s;
}

.cuadro-cover:hover .cuadro-cover-message {
    opacity: 1;
    border-radius: .5rem;
}


@media (max-width: 850px) {
    .main-cuadros h2 {
        font-size: 1.5rem;
    }

    .cuadros {
        padding: 0rem;
    }

    .cuadro {
        /*flex: 1 0 24rem;*/
        width: 40%;
        height: 23rem;
        margin: .5rem;
    }
}

@media (max-width: 480px) {
    .cuadros {
        padding: 0rem;
    }

    .cuadro {
        /*flex: 1 0 24rem;*/
        width: 43%;
        height: 15rem;
        margin: .5rem .5rem;
    }

}