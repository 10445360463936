.contenedor {
    width: 100%;
    height: auto;
    background-color: var(--primario);
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 0rem;
}

.sub-menus {
    width: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}

.sub-menu {
    background-color: var(--primario);
    border-radius: .5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 18rem;
    height: 15rem;
    margin: .6rem;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    font-size: 100%;
    overflow: hidden;
    position: relative;
}

.card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: .5s ease-in-out;
    background-color: var(--primario);
}

.front,
.back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border: 1px solid var(--primario);
}

.front {
    background-color: var(--blanco);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: rotateY(0deg);
}

.back {
    background-color: var(--blanco);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateY(180deg);
    color: var(--secundario);
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
}

.sub-menu:hover .card {
    transform: rotateY(180deg);
}

.encabezado {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    color: var(--primario);
    margin-right: .5rem;
}

.leyenda {
    font-size: 1rem;
    color: var(--secundario);
    font-weight: 600;
    padding: 0rem 1rem;
}

.imagen-icono {
    width: 8rem;
    height: auto;
    margin: .5rem;
}


@media only screen and (max-width: 850px) {
    .sub-menu {
        background-color: var(--primario);
        border-radius: .5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 45%;
        height: 12rem;
        margin: .5rem;
        transition: 0.3s;
        cursor: pointer;
        font-size: 100%;
        overflow: hidden;
        position: relative;
    }

    .imagen-icono {
        width: 7rem;
        height: auto;
    }
    .encabezado {
        font-size: 1.3em;
        font-weight: bold;
        color: var(--primario);
    }

    .leyenda {
        font-size: .9rem;
        color: var(--secundario);
    }
}

@media only screen and (max-width: 420px) {
    .sub-menu {
        background-color: var(--primario);
        border-radius: .5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 7rem;
        margin: .5rem;
        transition: 0.3s;
        cursor: pointer;
        font-size: 100%;
        overflow: hidden;
        position: relative;
    }

    .front {
        background-color: var(--blanco);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        transform: rotateY(0deg);
    }
    .imagen-icono {
        width: 6rem;
        height: auto;
    }
    .encabezado {
        font-size: 1.1em;
        font-weight: bold;
        text-align: start;
        color: var(--primario);
    }

    .leyenda {
        font-size: .8rem;
        color: var(--secundario);
    }
}