.header {
    background-color: var(--blanco);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    border-bottom: .3rem solid var(--tercero);
}

.contenido {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--primario);
    cursor: pointer;
    transition: 0.3s;
    padding: .5rem 1rem;
}

.contenido:hover {
    background-color: var(--tercero);
    border-radius: .3rem;
}

.menu-vertical {
    display: none;
    box-shadow: 2px 2px 4px var(--primario);
    padding: .1rem;

    transition: 1s ease-in-out;
}

.contenido:hover .menu-vertical {
    display: block;
}

.header-submenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 1rem;
}

.header .logo-show {
    cursor: pointer;
}
.header .logo img {
    height: 4.5rem;
    width: auto;
    margin: 0rem 1rem;
}
.header .logo-show img {
    height: 4.5rem;
    width: auto;
    margin: 0rem;
}
.item-menu {
    padding: .3rem .5rem;
    border-radius: .3rem;
    font-size: 1.2rem;
    
    font-weight: normal;
    color: var(--primario);
    transition: 0.3s;
    cursor: pointer;
}

.item-menu:hover {
    color: var(--negro);
    background-color: var(--tercero);
}

.menu-vertical {
    position: absolute;
    display: none;
    width: 20rem;
    height: 40rem;
    overflow: auto;
    padding: .1rem;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-radius: .5rem;
    background-color: var(--blanco);
    top: 0;
    right: 0;
    transform: translateY(4.2rem) translateX(-4.5rem);
    border: .1rem solid var(--primario);
    transition: 1s ease-in-out;
}

.menu-vertical::-webkit-scrollbar {
    width: .8rem;
    background-color: var(--tercero);
    border-radius: 2rem;
}

.menu-vertical::-webkit-scrollbar-thumb {
    background-color: var(--primario);
    border-radius: 2rem;
    
    border: 1px solid var(--blanco);
}

.contacto {
    font-size: 1.2rem;
    padding: .5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: var(--primario);
    border-radius: .3rem;
    cursor: pointer;
    transition: 0.3s;
}

.contacto:hover {
    background-color: var(--tercero);
    color: var(--negro);
}

.icon-menu {
    width: 1rem;
    height: auto;
    fill: var(--primario);
    margin: .2rem;
}

@media only screen and (max-width: 850px) {
    .header {
        background-color: var(--blanco);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5rem;
        position: sticky;
        top: 0;
        z-index: 999;
        width: 100%;
        border-bottom: .3rem solid var(--tercero);
    }
    .menu-vertical {
        width: 22rem;
        height: auto;
        transform: translateY(4rem) translateX(0rem);
    }
    .contacto {
        display: none;
    }
}

@media only screen and (max-width: 420px) {
    .menu-vertical {
        width: 18rem;
        height: 35rem;
        transform: translateY(4rem) translateX(0rem);
    }

    .header .logo img {
        display: none;
    }
    .header .logo-show {
        display: block;
    }
    .header .logo-show img {
        height: 4rem;
        width: auto;
        margin: 0rem;
    }
    /*
    .contenido {
        font-size: 1.2rem;
    }

    .item-menu {
        box-shadow: 1px 1px 1px var(--primario);
    }*/
}