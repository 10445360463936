/*
*{
    border: 1px solid red;
}*/
:root {
    --primario: rgb(106, 15, 73);
    --secundario: rgb(109, 128, 127);
    --tercero: rgb(255, 194, 206);
    --rojo: rgb(74, 0, 31);
    --verde: rgb(104, 193, 63);
    --blanco: #fefefe;
    --negro: #212121;
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Gibson';
    src: url('.//fonts/gibson/Gibson-Regular.ttf') format('truetype');
}

body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

/* Estilos para WebKit (Safari, Chrome) */

body::-webkit-scrollbar {
    width: 1rem;
    background-color: var(--tercero);
    border-radius: 1rem;
    border: 1px solid var(--blanco);

}

body::-webkit-scrollbar-thumb {
    background-color: var(--primario);
    border-radius: 1rem;
    border: 1px solid var(--blanco);
}