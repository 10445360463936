.notfound-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 28rem;
}
.notfound-title {
    
    text-align: center;
    font-size: 2rem;
    color: var(--primario);
    margin-top: 5rem;
}

.notfound-desc {
    text-align: center;
    font-size: 1.2em;
    color: var(--secundario);
    margin-bottom: 5rem;
}