.main-proteccion {
    padding-top: 1rem;
    background-color: var(--blanco);
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subtitulo-main-proteccion {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primario);
    margin: 1rem;
    text-align: center;
}

@media (max-width: 850px) {

    .subtitulo-main-proteccion {
        font-size: 2rem;
    }

}