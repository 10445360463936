.imgtexto-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    /* Ajusta el valor según tu diseño */
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.imgtexto-imagen{
    width: 50%;
    display: flex;
    justify-content: center;
}

.imgtexto-imagen img {
    max-width: 100%;
    height: auto;
    border-radius: .5rem;
    border: 2px solid var(--primario);
    transition: .3s ease-in-out;
}
.imgtexto-imagen img:hover {
    transform: scale(1.05);
}

.imgtexto-contenido {
    flex: 1;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.imgtexto-titulo {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--primario);
    font-weight: bold;
}

.imgtexto-texto {
    font-size: 1rem;
    color: var(--secundario);
}




@media (max-width: 850px) {
    .imgtexto-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        /* Ajusta el valor según tu diseño */
        margin: 0 auto;
        padding: 20px;
        box-sizing: border-box;
    }
    .imgtexto-imagen{
        width: 100%;
    }

}

@media (max-width: 480px) {
    .imgtexto-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        /* Ajusta el valor según tu diseño */
        margin: 0 auto;
        padding: 20px;
        box-sizing: border-box;
    }

    .imgtexto-contenido {
        flex: 1;
        padding: 0.5rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

}