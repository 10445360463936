.footer {
    border-top: .1rem solid var(--primario);
    background-color: var(--blanco);
    padding: 1rem 0rem;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 1rem 1rem;
}
.row-logos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 1rem 1rem;
}
img {
    height: 4.5rem;
    transition: 0.3s;
}

.col a{
    width: 20%;
    height: auto;
}
.footer-content {
    text-align: center;
}
footer span {
    font-size: 1rem;
    font-weight: bold;
}

@media only screen and (max-width: 820px) {
    img {
        height: 3rem;
    }
}

@media only screen and (max-width: 420px) {
    img {
        height: 2rem;
    }
}