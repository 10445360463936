.principal {
    background-color: var(--blanco);
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.titulo-main {
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--negro);
    text-align: center;
    margin: .5rem;
}

.subtitulo-main {
    font-size: 2rem;
    font-weight: 400;
    color: var(--primario);
    text-align: center;
    margin: .0rem;
    margin-bottom: .5rem;
}

.main-mapa-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent), url('./mapa-curvas.png');
    width: 100%;
    height: auto;
    padding: 0rem;
    border-top: 2px solid var(--negro);
}

.main-mapa-info {
    width: 50%;
    margin-right: 2rem;
    margin-left: 6rem;
}

.main-mapa-espace {
    border-radius: 0rem;
    position: relative;
    height: auto;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s;
    overflow: hidden;
    width: 50%;
}

.main-mapa-titulo {
    font-size: 2.4rem;
    color: var(--blanco);
    font-weight: bold;
    text-shadow: 2px 2px 50px rgba(0, 0, 0, 0.7);
    -webkit-text-stroke: 1px var(--blanco);
}

.main-mapa-subtitulo {
    margin-top: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--blanco);
    text-shadow: 2px 2px 50px rgba(0, 0, 0, 0.8);
}

.mapa-prueba {
    width: 100%;
    height: auto;
    background-position: center;
    background-attachment: fixed;
    cursor: pointer;
    filter: brightness(100%);
    box-shadow: 2px 2px 4px var(--primario);
    filter: saturate(1.8);
    filter: contrast(110%);
    transition: .3s ease-in-out;
}

.contenedor-img {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease-in-out;
}

.contenedor-img .texto-img {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    visibility: hidden;
    font-weight: bold;
    color: var(--blanco);
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 1rem;
    text-shadow: 2px 2px 50px rgba(0, 0, 0, 1);
    transition: .3s ease-in-out;
}
.main-mapa-espace {
    transition: .3s ease-in-out;
}
.main-mapa-espace:hover .texto-img {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.6);
}

.main-mapa-espace:hover .mapa-prueba {
    filter: blur(1px);
}

@media only screen and (max-width: 820px) {
    .titulo-main {
        font-size: 2rem;
        margin: 0rem 2rem;
    }

    .subtitulo-main {
        font-size: 1.6rem;
        margin: 0rem 2rem;
    }

    .main-mapa-container {
        flex-direction: column-reverse;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent), url('./mapa-curvas.png');
        width: 100%;
        height: auto;
    }

    .main-mapa-espace {
        width: 100%;
        height: auto;
    }

    .main-mapa-info {
        margin: 1rem 0rem;
        width: 90%;
    }

    .main-mapa-titulo {
        font-size: 2.2rem;
        color: var(--blanco);
        font-weight: bold;
        text-align: center;
    }

    .main-mapa-subtitulo {
        font-size: 1.4rem;
        color: var(--blanco);
        text-align: center;
    }
}

@media only screen and (max-width: 420px) {
    .titulo-main {
        font-size: 1.2rem;
    }

    .subtitulo-main {
        font-size: 1rem;
    }

    .main-mapa-container {
        flex-direction: column-reverse;
        width: 100%;
        height: auto;
    }

    .main-mapa-espace {
        width: 100%;
        height: auto;
    }

    .main-mapa-info {
        margin: .5rem 0rem;
        width: 90%;
    }

    .main-mapa-titulo {
        font-size: 1.2rem;
        color: var(--blanco);
        font-weight: bold;
        text-align: center;
    }

    .main-mapa-subtitulo {
        font-size: 1rem;
        color: var(--blanco);
        text-align: center;
    }
}