.folder-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--rojo);
  width: 100%;
  height: auto;
  padding: 2rem 0rem;
}

.folder-item {
  text-decoration: none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin: .8rem;
  width: 18%;
  height: 3rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--blanco);
  transition: .3s ease-in-out;
}

.folder-item:hover {
  transform: scale(1.01);
}

.folder-item img {
  width: 3rem;
  height: auto;
}

.folder-item div {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--primario);
}

/* Estilos adicionales para la responsividad */

@media (max-width: 768px) {
  .folder-item {
    width: 15rem;
    padding: 1.5rem  2rem;
  }
  .folder-item div {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .folder-container {
    flex-direction: column;
  }
  .folder-item {
    width: 80%;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: .2rem;
    padding: .5rem  .5rem;
  }
  .folder-item img {
    width: 2rem;
    height: auto;
  }
  .folder-item div {
    font-size: 1.2rem;
  }
}