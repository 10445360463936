.main-baner-texto {
    width: 100%;
    height: auto;
    background-color: var(--primario);
    margin: 0rem;
    padding-bottom: 1rem;
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.titulo-baner-texto {
    text-align: center;
    font-size: 1.5rem;
    color: var(--blanco);
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.parrafo-baner-texto {
    font-size: 1.1rem;
    text-align: left;
    color: var(--blanco);
    padding: 0rem 16rem;
    text-align: justify;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

@media only screen and (max-width: 820px) {
    .titulo-baner-texto {
        font-size: 1.4rem;
    }

    .parrafo-baner-texto {
        font-size: 1rem;
        padding: 0rem 1rem;
    }

}

@media only screen and (max-width: 420px) {
    .titulo-baner-texto {
        font-size: 1.4rem;
    }

    .parrafo-baner-texto {
        font-size: .9rem;
        padding: 0rem .5rem;
    }
}