.main-infografia {
    padding-top: 1rem;
    background-color: var(--blanco);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subtitulo-main-infografia {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primario);
    margin: 1rem;
}

.infografia-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
}

.infografia-container img {
    max-width: 25rem; /* Establecer un ancho máximo */
    height: auto;
    border: 0.1rem solid var(--primario);
    border-radius: 1rem;
    margin: 1rem;
    box-shadow: 0 2px 4px var(--negro);
    transition: .3s;
    object-fit: contain; /* Mantener la proporción, ajustando contenido */
}


.infografia-container img:hover {
    transform: scale(1.08);
}

@media (max-width: 850px) {
    .infografia-container img {
        width: 40%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .infografia-container img {
        width: 90%;
        height: auto;
    }
}