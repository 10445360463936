.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.dialog.show {
    display: flex;
}

.dialog-content {
    background-color: var(--blanco);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 20px var(--negro);
    max-width: 50rem;
    width: 100%;
    text-align: justify;
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.dialog-header h2 {
    margin: 0;
    font-size: 2rem;
}

.dialog-body {
    padding: .5rem 1rem;
    /*
    border: 1px solid var(--primario);*/
    height: 20rem;
    width: auto;
    overflow: auto;
    margin-bottom: 1rem;
}

.dialog-body::-webkit-scrollbar {
    width: .8rem;
    background-color: var(--tercero);
    border-radius: 2rem;
    border: 1px solid var(--blanco);
}

.dialog-body::-webkit-scrollbar-thumb {
    background-color: var(--primario);
    border-radius: 2rem;
    border: 1px solid var(--blanco);
}

.dialog-body p {
    font-size: 1rem;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
}

.confirm-button {
    border: none;
    background-color: var(--primario);
    color: var(--blanco);
    padding: .5rem 1rem;
    margin-left: 10px;
    cursor: pointer;
    transition: .3s;
    font-size: 1.2rem;
    border-radius: .5rem;
}

.confirm-button:hover {
    background-color: var(--secundario);
}

@media only screen and (max-width: 820px) {

    .dialog-content {
        width: 80%;
    }

    .dialog-body {
        padding: 0rem;
        /*
        border: 1px solid var(--primario);*/
        height: 25rem;
        width: auto;
        overflow: auto;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 420px) {
    .dialog-content {
        width: 85%;
        padding: 1rem;
        border-radius: 1rem;
    }

    .dialog-body {
        padding: 0rem;
        /*
        border: 1px solid var(--primario);*/
        height: 20rem;
        width: auto;
        overflow: auto;
        margin-bottom: 1rem;
    }

    .dialog-body p {
        font-size: .8rem;
    }


    .dialog-header h2 {
        margin: 0;
        font-size: 1.5rem;
    }
}