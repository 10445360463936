.main-enlaces {
    width: 100%;
    padding-top: .1rem;
    padding-bottom: .1rem;
    background-color: var(--rojo);
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.slide-main {
    margin: 2rem .5rem;
    font-size: 1.5rem;
    color: var(--blanco);
    font-weight: bold;
}

.enlace {
    padding: .1rem 0rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

a {
    font-size: 1rem;
    font-weight: lighter;
    color: var(--tercero);
}

@media only screen and (max-width: 420px) {
    .slide-main {
        width: 22rem;
    }
}