.main-mini {
    font-size: 2rem;
    color: var(--negro);
    width: 100%;
    font-weight: bold;
    background-color: var(--tercero);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contenedor-mini {
    font-weight: normal;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.botton-mini {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--blanco);
    border: none;
    text-align: center;
    padding: .5rem;
    border-radius: 1rem;
    width: 12rem;
    height: 10rem;
    cursor: pointer;
    margin: .5rem 1rem;
    border: 2px solid var(--blanco);
    box-shadow: 2px 2px 4px var(--primario);
    transition: 0.3s ease-in-out;
    font-weight: bold;
}

.main-mini-title {
    color: var(--primario);
    font-size: 1.2rem;
}

.botton-mini:hover {
    border: 2px solid var(--primario);
}

.botton-mini:focus {
    outline: none;
}

.imagen-icono-minimenu {
    width: 7rem;
    height: auto;
}

.oscuro {
    background-color: var(--blanco);
    border: 2px solid var(--blanco);
    box-shadow: 2px 2px 4px var(--primario);
    width: 20rem;
}

.oscuro:hover {
    border: 2px solid var(--primario);
}

.oscuro-title {
    font-size: 1rem;
    color: var(--primario);
}

.oscuro-img {
    width: 6rem;
}


@media only screen and (max-width: 820px) {
    .botton-mini {
        padding: .5rem;
        border-radius: 1rem;
        width: 12rem;
        height: 11rem;
        cursor: pointer;
        margin: .5rem .5rem;
    }

    .oscuro {
        width: 15rem;
    }
}

@media only screen and (max-width: 420px) {
    .botton-mini {
        flex-direction: row;
        padding: .5rem;
        border-radius: 1rem;
        width: 80%;
        height: 5rem;
        cursor: pointer;
        margin: .5rem 1rem;
    }

    .imagen-icono-minimenu {
        width: 5rem;
        height: auto;
    }

    .oscuro {
        width: 80%;
        height: 9rem;
        flex-direction: column;
    }

    .oscuro-img {
        margin-top: -1rem;
        width:6rem;
    }

}

