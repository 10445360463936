.slider {
    width: 100%;
    height: auto;
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-slider-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: var(--negro);
}

.container-images {
    margin-top: -.8rem;
    border-radius: 1rem;
    border: .2rem solid var(--tercero);
    transition: .3s ease-in-out;
}

.container-images:hover {

    box-shadow: 2px 2px 4px var(--tercero);
    transform: scale(1.02);
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.titulo-fotos {
    font-size: 2rem;
    text-align: center;
    padding: .5rem;
    font-weight: bold;
}

.button {
    background-color: var(--primario);
    color: var(--blanco);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 0 10px;
    transition: 0.3s;
    font-weight: bold;
}

.button:hover {
    background-color: var(--tercero);
    color: var(--negro);

}

.button:focus {
    outline: none;
}

@media (max-width: 850px) {

    .image-slider-title {
        font-size: 1.5rem;
    }
    .container-images {
        width: 95% !important;
    }
    
}