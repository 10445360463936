.main-docLinks {
    width: 100%;
    height: auto;
    background-color: var(--rojo);
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.docLinks-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: var(--blanco);
}

.card-doc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--blanco);
    border-radius: 1rem;
    box-shadow: 2px 2px 4px var(--negro);
    padding-bottom: .5rem;
    width: 65%;
}

.titulos h3 {
    font-size: 1.2rem;
    font-weight: bold;
}

.titulo-docLinks {
    font-size: 2rem;
    font-weight: 600;
    margin: .5rem;
    text-align: center;
    color: var(--blanco);
}

.titulos {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.contenedor-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    padding: .5rem;
    border-radius: .5rem;
    margin: .5rem;
    box-shadow: 2px 2px 4px var(--negro);
    justify-content: space-between;
    transition: .3s ease-in-out;
}

.contenedor-link:hover {
    transform: scale(1.01);
}

.contenedor-link:hover .botton-docLinks {
    color: var(--negro);
}

.botton-docLinks {
    color: var(--secundario);
    font-weight: 600;
    transition: .3s ease-in-out;
}

.a-docLinks {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s;
}

.a-docLinks:hover {
    transform: scale(1.2);
}

.a-docLinks img {
    width: 2.5rem;
    height: auto;
    margin-right: 1rem;
}

.contenedor-links-imagenes-docs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 850px) {
    .docLinks-title {
        font-size: 1.5rem;
    }

    .card-doc {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .card-doc {
        width: 95%;
    }
}