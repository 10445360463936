.floating-button {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-40%);
    background-color: var(--tercero);
    padding: 1rem .5rem;
    border-radius: 0 1rem 1rem 0;
    /* Aplica el radio de borde solo en el lado derecho */
    border: .1rem solid var(--primario);
    z-index: 9999;
    cursor: pointer;
    transform-origin: center;
}

.floating-button a {
    text-decoration: none;
    color: var(--primario);
    font-weight: bold;
    font-size: 1rem;
    writing-mode: vertical-rl;
}