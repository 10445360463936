.main-suelo {
    padding-top: 1rem;
    background-color: var(--blanco);
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subtitulo-main-suelo {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primario);
    margin: 1rem;
    text-align: center;
}

.biodiversidad-container-videos {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    gap: 1rem;
    width: 100%;
    align-items: center;
    margin-top: 1rem;
}

Youtube {
    width: 42%;
}

@media (max-width: 850px) {
    .subtitulo-main-suelo {
        font-size: 2rem;
    }

    .biodiversidad-container-videos {
        flex-direction: column;
    }

    Youtube {
        width: 80% !important;
    }

}

@media (max-width: 480px) {
    Youtube {
        width: 90% !important;
        height: auto !important;
        object-fit: contain !important;
    }

    iframe {
        width: 90% !important;
    }
}