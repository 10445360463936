.links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--tercero);
  width: 100%;
  height: auto;
  padding: 1rem 0rem;
}

.links-titulo {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--primario);
}

.links-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: auto;
}

.link-item {
  margin: 10px;
  padding: 10px;
  width: 50%;
  height: auto;
  border: .1rem solid var(--primario);
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  background-color: var(--blanco);
  color: var(--primario);
  border-radius: 1rem;
  transition: .3s ease-in-out;
}

.link-item:hover {
  transform: scale(1.02);
}

@media (max-width: 850px) {

  .link-item {
    padding: 5px;
    width: 90%;
  }

}