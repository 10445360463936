/* styles.css */
.main-books {
    background-color: var(--primario);
    margin: 0rem;
    width: 100%;
    padding-bottom: 1rem;
}

.books {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0rem 3rem;
}

.main-books h2 {
    color: var(--blanco);
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.book {
    /*flex: 1 0 24rem;*/
    width: 30rem;
    height: 15rem;
    margin: .8rem;
    background-color: var(--blanco);
    border-radius: .5rem;
    padding: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: .1rem solid var(--blanco);
    transition: 0.3s;
}

.book:hover {
    box-shadow: 2px 2px 8px var(--negro);
    transform: scale(1.03);
}

.book-cover:hover .book-img {
    filter: blur(1px);
}

.book .book-cover {
    position: relative;
    height: auto;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s;
    overflow: hidden;
    width: 35%;
}

.book-cover-message {
    border-radius: 5rem;
}

.book-cover {
    border-radius: .5rem;
}

.book .book-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5rem;
    filter: brightness(0.8);
}

.book-cover img:hover {
    transform: scale(1.01);
}

.book .book-cover-message {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--blanco);
    padding: 10px;
    font-size: 1rem;
    opacity: 0;
    transition: 0.3s;
}

.book-cover:hover .book-cover-message {
    opacity: 1;
    border-radius: .5rem;
}

.book .book-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 65%;
    padding: 1rem 0.5rem;
}

.book .book-title {
    color: var(--primario);
    font-weight: bold;
    font-size: 1.2rem;
    padding-bottom: 1rem;
}

.book .book-description {
    color: var(--secundario);
    font-size: 1rem;
    width: 100%;
    height: auto;
}

@media (max-width: 850px) {
    .main-books h2 {
        font-size: 1.5rem;
    }
    .books {
        padding: 0rem;
    }

    .book {
        /*flex: 1 0 24rem;*/
        width: 45%;
        height: 12rem;
        margin: .5rem;
    }

    .book .book-details {
        padding: .5rem 0.2rem;
    }

    .book .book-title {
        font-size: 1rem;
        padding-bottom: 1rem;
    }

    .book .book-description {
        font-size: .8rem;
    }

}

@media (max-width: 480px) {
    .books {
        padding: 0rem;
    }

    .book {
        /*flex: 1 0 24rem;*/
        width: 95%;
        height: 10rem;
        margin: .5rem 0rem;
    }

    .book .book-details {
        padding: .5rem 0.2rem;
    }

    .book .book-title {
        font-size: 1rem;
        padding: 0rem;
    }

    .book .book-description {
        font-size: .7rem;
    }

}