.image-gallery {
    margin: 0rem;
    background-color: var(--balnco);
    width: 100%;
    height: auto;
    padding-bottom: 1rem;
}

.image-gallery-title {
    text-align: center;
    font-size: 1.8rem;
    color: var(--negro);
}

.image-gallery-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    gap: 2rem 1rem;
}

.image-gallery-item {
    width: 35%;
    height: 35%;
    margin: 1rem;
}

.image-gallery-item img {
    width: 100%;
    height: 100%;
    border-radius: .5rem;
    transition: .3s;
    box-shadow: 2px 2px 15px var(--negro);
}

.image-gallery-item img:hover{
    transform: scale(1.05);
}

/* Estilos adicionales para la responsividad */

@media (max-width: 850px) {
    .image-gallery-item {
        width: 40%;
        height: auto;
        margin: 0rem;
    }

}

@media (max-width: 480px) {
    .image-gallery-item {
        width: 80%;
        height: auto;
        margin: 0rem;
    }
}