
.main-contacto {
    padding-top: 3rem;
    padding-bottom: 5rem;
    background-color: var(--blanco);
    /*alinear los items de manera vertical y centrados*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titulo {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 3rem;
    font-weight: bold;
    color: var(--negro);
}

.subtitulo {
    padding-top: .5rem;
    font-size: 2rem;
    color: var(--primario);
}

.parrafo {
    padding-bottom: .5rem;
    font-size: 1.2rem;
    color: var(--negro);
}

.mapa {
    margin-top: 3rem;
    width: 90%;
    height: 30rem;
    border-radius: 1rem;
    box-shadow: 2px 2px 4px var(--primario);
}