/* CSS para el componente Enlace */
.enlaces {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secundario);
  cursor: pointer;
  width: 100%;
  height: auto;
  padding-bottom: 2rem;
}

.enlaces h3 {
  font-size: 2rem;
  color: var(--blanco);
}

.enlaces a {
  text-decoration: none;
  color: var(--secundario);
  border-radius: 2rem;
  background-color: var(--blanco);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  transition: .3s ease-in-out;
  margin-bottom: 1rem;
}

.enlaces img {
  width: 20%;
  height: auto;
  margin: 1rem;
  border-radius: 2rem;
  transition: .3s ease-in-out;
}

.enlaces-link {
  width: 70%;
  margin: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  overflow-wrap: break-word;
  text-align: center;
}

.enlaces a:hover img {
  filter: saturate(150%);
  border-radius: .5rem;
}

.enlaces a:hover {
  border-radius: .5rem;
}



@media (max-width: 850px) {
  .enlace a {
    width: 95%;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .enlace img {
    width: 30%;
    height: auto;
    margin: 1rem;
    border-radius: 2rem;
    transition: .3s ease-in-out;
  }

  .enlace-link {
    width: 60%;
    margin: 1rem;
    font-size: 1.4rem;
    font-weight: 700;
    overflow-wrap: break-word;
    text-align: center;
  }

}
@media only screen and (max-width: 420px) {
  .enlace img {
    width: 20%;
    height: auto;
    margin: 1rem;
    border-radius: 2rem;
    transition: .3s ease-in-out;
  }

  .enlace-link {
    width: 60%;
    margin: 1rem;
    font-size: 1rem;
    font-weight: 700;
    overflow-wrap: break-word;
    text-align: center;
  }
}